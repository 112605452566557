import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export type ImageProps = {
  wrapperClassName?: string;
  imgClassName?: string;
  src: string;
  alt: string;
} & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const Image = (props: ImageProps) => {
  return (
    <>
      <noscript>
        <span className={props.wrapperClassName}>
          <img className={props.imgClassName} src={props.src} alt={props.alt} />
        </span>
      </noscript>
      <LazyLoadImage
        wrapperClassName={props.wrapperClassName}
        className={props.imgClassName}
        src={props.src}
        alt={props.alt}
        effect="opacity"
        srcSet={props.srcSet}
        sizes={props.sizes}
      />
    </>
  );
};

export default Image;
